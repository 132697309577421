import(/* webpackMode: "eager" */ "/codebuild/output/src4031196643/src/apps/wrap.gifts/public/images/LogoWideFixed.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4031196643/src/apps/wrap.gifts/src/components/layouts/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4031196643/src/apps/wrap.gifts/src/components/layouts/navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4031196643/src/apps/wrap.gifts/src/services/AppProviders.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4031196643/src/apps/wrap.gifts/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4031196643/src/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4031196643/src/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"variable\":\"--font-spaceGrotesk\"}],\"variableName\":\"spaceGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4031196643/src/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":\"400\",\"variable\":\"--font-poppins\",\"subsets\":[\"latin\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4031196643/src/node_modules/@aws-amplify/ui-react/dist/styles.css");
